body {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
  font-family: "DotGothic16", sans-serif;
}
.navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  z-index: 100;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 800px;
}
.main-tittle {
  margin-top: 100px;
}
h1 {
  font-size: 51px;
}
/* .App {
  text-align: right;
  padding: 20px;
} */

.navbar-brand {
  margin: 0 200px;
}

/* form {
  margin: 0 auto;
  border-radius: 8px;
} */

form .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column; /* Đặt các thành phần theo cột */
}

form .form-group label {
  margin-bottom: 5px;
  text-align: left; /* Căn trái các label */
}

form .form-group input,
form .form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.navbar-toggle:hover {
  background-color: transparent;
  border: none;
  outline: none;
}
/* button {
  background-color: #0077b5;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
} */

/* button:hover {
  background-color: #005f8b;
} */

.spinner-border {
  margin: 20px auto;
}

/* p {
  text-align: left;
  margin-bottom: 0;
} */

ul li button:hover {
  text-decoration: underline;
}

.create-agent-container,
.register-container,
.login-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.nav-welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 200px;
  gap: 10px;
}
.navbar-nav a,
.navbar-nav {
  text-align: center;
}
.text-02 {
  font-size: 15px;
  letter-spacing: 0.025rem;
  width: calc(100% + 0.025rem);
  font-size: 1em;
  line-height: 2;
  font-weight: 400;
}
.footer-text {
  font-size: 10px;
}
.footer-infor {
  font-size: 11px;
}
.slide-in-top {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  nav .container-fluid {
    margin: 0 20px;
    padding: 0;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .navbar-brand {
    margin: 0;
  }
  h1 {
    font-size: 3em;
    line-height: 1;
  }
  h2 {
    font-size: 2em;
    line-height: 1;
  }
  .text-02 {
    font-size: 1em;
  }
  .nav-welcome {
    margin: 0;
  }
  .ms-auto {
    margin-left: 0 !important;
  }
  .wrapper p {
    margin-bottom: 10px;
  }
}
@keyframes fadeInContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animate-slide-down {
  transform: translateY(-100%);
  opacity: 0;
  animation: slideDown 1s forwards;
  animation-delay: 1s;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
