.conclusion-invest-wrappper {
  margin: 0 200px;
}
.text-03 {
  font-size: 18px;
  letter-spacing: 0.025rem;
  width: calc(100% + 0.025rem);
  line-height: 2;
  text-align: center;
  font-weight: bolder;
  margin: 40px 0;
}
.demonstration-container input {
  font-size: 18px;
  color: #242424;
  background-color: #ededed;
  padding: 16px 22px;
  width: 28%;
  border: none;
  border-radius: 16px;
  margin: 30px 0;
}
.demonstration-container input:nth-last-child(2) {
  margin-right: 30px;
}
.demonstration-container span {
  text-align: center;
}
.demonstration-container {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}
.send-btn {
  border: none;
  background-color: transparent !important;
  color: #474747;
  font-size: 25px;
}
.form-2 input {
  font-size: 18px;
  color: #474747;
  background-color: #ededed;
  padding: 16px 22px;
  width: 60%;
  border: none;
  border-radius: 16px;
}
.form-intent input,
.form-intent select {
  width: 60%;
  position: relative;
  font-size: 18px;
  padding: 16px 22px;
  border-radius: 12px;
  border: none;
  color: #474747;
  margin-bottom: 10px;
}
.form-2 textarea {
  width: 60%;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
  border-radius: 10px;
  color: #242424;
  background-color: #ededed;
  border: 1px solid transparent;
  padding: 0.875rem;
  height: 300px;
  line-height: 1.5;
  padding-top: 0.6125rem;
}
.form-2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  text-align: center;
}
.success-message {
  color: rgb(55, 179, 55);
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}
/* popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.popup-content p {
  font-size: 16px;
  margin-top: 16px;
  color: #4caf50;
}

.close-btn {
  color: #212529;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
}

@media (max-width: 768px) {
  .conclusion-invest-wrappper {
    margin: 20px;
  }
  .demonstration-container input {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }
  .demonstration-container {
    display: flex;
    flex-direction: column;
  }
  .demonstration-container input:nth-last-child(2) {
    margin: 0;
    margin-bottom: 10px;
  }
  .form-2 input,
  .form-2 textarea,
  .form-2 select {
    width: 100%;
  }
}
