.spinner-border {
  margin: 20px auto;
}
.register-wrapper {
  width: 100vw;
  padding: 0 200px;
  font-family: "DotGothic16", sans-serif;
}
.register-container h1 {
  font-size: 100px;
  font-family: "DotGothic16", sans-serif;
}
.register-container hr {
  margin: 40px 0;
  align-items: center;
  display: flex;
  position: relative;
  width: 80%;
}
.inner-register {
  display: flex;
  flex-direction: column;
}
form {
  background-color: transparent !important;
}
.inner-form input {
  font-size: 16px;
  border-radius: 0.75rem;
  color: #242424;
  background-color: #ededed;
  padding: 16px 22px;
  width: 70%;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
}
.register-btn {
  margin-top: 20px;
  border: none;
  background-color: transparent !important;
  color: #474747;
  font-size: 25px;
  margin-bottom: 30px;
}
.divider {
  margin-bottom: 120px;
}
.file-input-wrapper {
  position: relative;
  width: 70%;
  margin-top: 20px;
}

.file-input-wrapper input[type="file"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.file-input-label {
  display: flex;
  align-items: center;
  background-color: #ededed;
  padding: 8px 6px 8px 22px;;
  border-radius: 10px;
  font-size: 16px;
  font-family: "DotGothic16", sans-serif;
  color: #242424;
  position: relative;
}

.file-input-text {
  flex-grow: 1;
  color: #757575;
}
.onpen-file-btn {
  border-radius: 10px;
  padding: 8px 12px;
  background-color: #fafafa;
  border: none;
}
.onpen-file-btn img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.onpen-file-btn:hover {
  background-color: #fafafa;
}
.error-msg{
  color: rgb(224, 13, 13);
  font-size: 16px;
}
@media (max-width: 768px) {
  .register-wrapper {
    padding: 20px;
  }
  .inner-register {
    width: 100%;
  }
  .inner-form input,
  .file-input-wrapper {
    width: 100%;
  }
}
