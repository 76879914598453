.chat-container {
  font-family: "DotGothic16", sans-serif;
  margin: 0 200px;
}

.messages-container {
  max-height: 520px;
  width: 80%;
  min-height: 52vh;
  overflow-y: auto;
  padding: 10px;
  border-radius: 8px;
  padding: 20px;
  margin: auto;
}

.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.message.user-message {
  align-items: flex-end;
}

.message.agent-message {
  align-items: flex-start;
}

.message-content {
  padding: 10px;
  border-radius: 8px;
  max-width: 70%;
  word-wrap: break-word;
  font-size: 12px;
}

.message-time {
  font-size: 0.7em;
  color: #888;
  margin-top: 5px;
}

.form-group-chat {
  display: flex;
  width: 80%;
  align-items: center;
  margin: 0 auto;
}

.form-input-chat {
  flex: 1;
  display: flex;
  position: relative;
}
.spinner-message-container {
  min-height: 60vh;
  display: flex;
  align-items: center;
  align-items: center;
}
.form-input-chat input {
  border: none;
  outline: none;
  background: #f1f1f1;
  border-radius: 25px;
  font-family: "DotGothic16", sans-serif;
  font-size: 12px;
  padding: 20px;
}
.chat-btn {
  position: absolute;
  right: 10px;
  bottom: 8px;
  background-color: #cccccc;
  color: white;
  outline: none;
  border-radius: 50%;
  border: none;
  padding: 8px 10px;
}

.chat-btn svg {
  color: white;
  cursor: pointer;
  font-size: 12px;
  fill: none;
  stroke: currentcolor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
}
.chat-btn:hover {
  background-color: #8b8787;
}
.spinner-chat {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  bottom: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-info-devider {
  margin: 0;
  border-color: #9ca19f;
  margin-bottom: 10px;
}
.footer-chat {
  margin-top: 120px;
}
.agent-info {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.agent-name {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}
.agent-role {
  font-size: 14px;
  color: #666;
}

@media (max-width: 767px) {
  .chat-container {
    margin: 20px;
    padding: 0;
  }
  .messages-container {
    width: 100%;
  }
  .message-content {
    max-width: 90%;
  }
  .form-group-chat {
    width: 100%;
  }
  .chat-btn {
    position: absolute;
  }
  .chat-btn:hover {
    background-color: #cccccc !important;
  }
}
