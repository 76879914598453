.profile-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 200px;
}

.profile-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.profile-info {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .profile-wrapper {
    margin: 20px;
  }
}
