.transaction-container {
  margin: 0 200px;
  min-height: 30vh;
}

.transaction-form {
  display: flex;
  flex-direction: column;
  height: 30vh;
  gap: 15px;
}
.transaction-form input,
.transaction-form select {
  font-size: 16px;
  border-radius: 0.75rem;
  color: #242424;
  background-color: #ededed;
  padding: 16px 22px;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.transaction-container select option {
  font-size: 14px;
}
.form-row {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}
.spinner {
  margin: 0 auto;
}
.form-group {
  flex: 1;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group select,
.form-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%; /* Ensure the inputs take up the full width */
}
.agent-transaction-subtmit-btn {
  background-color: #0077b5;
  color: white;
  border: none;
  padding: 6px 8px;
  cursor: pointer;
  border-radius: 4px;
  width: 20%;
  margin: 0 auto;
}
.agent-amount {
  color: rgb(198, 27, 27);
}

.transaction-history {
  margin-top: 20px; /* Add margin to separate the history from the form */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}
.list-transaction-container {
  min-height: 40vh;
}
.table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}
.loading-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.table th,
.table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #f5f5f5;
}
.table tr td a {
  color: #242424;
}
@media (max-width: 767px) {
  .transaction-container {
    margin: 20px;
  }
  p {
    margin-top: 20px;
    font-size: 0.9em;
  }
  .table {
    margin-top: 0;
  }
  .transaction-form {
    height: auto;
  }
  .transaction-form select {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .transaction-form input {
    margin-top: 0;
  }
  .agent-transaction-subtmit-btn {
    margin-bottom: 20px;
    width: auto;
  }
  .form-row {
    display: grid;
    grid-template-columns: auto;
  }
  table td {
    font-size: 12px;
  }
  .table td {
    padding: 10px 0;
  }
  .form-group label {
    font-size: 1em;
  }
  table th {
    font-size: 13px;
  }
}
