.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.logo {
  width: 100px;
  border-radius: 50%;
  margin-bottom: 50px;
}
.wrapper .inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "DotGothic16", sans-serif;
  position: relative;
  width: 55rem;
  max-width: 100%;
  border-radius: inherit;
}
.wrapper h1 {
  color: #474747;
  font-family: "DotGothic16", sans-serif;
  margin-bottom: 40px;
}
.wrapper p {
  letter-spacing: 0.025rem;
  font-size: 16px;
  line-height: 2;
}

.wrapper input {
  padding: 14px 260px 14px 16px;
  margin-top: 20px;
  border: 2px solid #474747;
  border-radius: 40px;
  font-size: 1em;
  font-family: "DotGothic16", sans-serif;
  letter-spacing: 0.025rem;
  font-weight: 400;
  border-radius: 2.5rem;
  color: #474747;
  border: solid 5px #474747;
}
.wrapper input:focus {
  outline: none !important;
  border: 5px solid #474747;
  box-shadow: 0 0 10px #719ece;
}
.login-btn {
  display: inline-block;
  padding: 16px;
  font-weight: 400;
  border-radius: 40px;
  border: none;
  text-overflow: ellipsis;
  background-color: #ceff00;
  color: #474747;
  width: 25%;
  position: relative;
  margin-top: 20px;
}
.login-btn:hover {
  background-color: #bfdb4e;
}
.welcome-text,
.register-text {
  color: #242424;
  font-family: "DotGothic16", sans-serif;
  letter-spacing: 0.025rem;
  width: 80%;
  font-size: 15px;
  line-height: 2;
  font-weight: 400;
  word-break: break-all;
  margin: 20px 0;
  text-decoration: none;
}
.form-login {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}
/* Media queries for responsive design */
@media (max-width: 768px) {
  .wrapper {
    margin: 20px;
    justify-content: flex-start;
  }
  .wrapper .inner {
    width: 100%;
    padding: 0;
  }
  .wrapper h1 {
    font-size: 3em;
    width: 100%;
  }
  .wrapper input {
    padding: 16px 20px;
    width: 100%;
  }
  .login-btn {
    width: 50%;
    margin: 0 auto;
    margin-top: 20px;
    padding: 16px 20px;
  }
  .password {
    margin-top: 8px;
  }
  .form-login {
    margin: 0;
    width: 100%;
  }
  .welcome-text,
  .register-text {
    width: 100%;
    font-size: 1em;
  }
}
